/* global gettext */
import { render } from "preact"
import { useState, useMemo } from "preact/hooks"
import { qs, qsa } from "../utils/dom"

export const initPolls = () => {
  let data
  for (let pollEl of qsa("[data-poll]")) {
    if ((data = qs("script", pollEl))) {
      render(<PollsApp data={JSON.parse(data.textContent || {})} />, pollEl)
    }
  }
}

const PollsApp = ({ data }) => {
  const [voted, setVoted] = useState(Boolean(localStorage.getItem("voted")))
  const [voting, setVoting] = useState(data)
  const maxVotes = useMemo(
    () => voting.reduce((a, b) => a + b.votes, 0),
    [voting],
  )

  const percents = useMemo(
    () =>
      voting.map((item, index, array) => {
        if (item.votes === 0) return 0
        if (index >= array.length - 1) {
          const rest = array.reduce((accumulator, currentValue, index) => {
            return index >= array.length - 1
              ? accumulator
              : accumulator + Math.round((currentValue.votes / maxVotes) * 100)
          }, 0)
          return 100 - rest
        }
        return Math.round((item.votes / maxVotes) * 100)
      }),
    [maxVotes],
  )

  const vote = (id) => {
    if (voted) return
    localStorage.setItem("voted", true)
    setVoting((prev) => {
      const newarray = [...prev]
      newarray.find((item) => item.id === id).votes++
      return newarray
    })
    setVoted(true)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    try {
      if (confirm(gettext("POLL_CONFIRM"))) {
        const voteId = Number(e.submitter.dataset.id)
        const voteUrl = e.submitter.dataset.vote

        // const url = `/poll-test/vote/${voteId}`
        fetch(voteUrl)
          .then((data) => {
            if (data.status !== 200) throw new Error("fetch not 200")

            vote(voteId)
          })
          .catch((err) => console.error(err))
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={onSubmit} className="polls">
      {voting.map((pollitem, index) => (
        <PollItem
          key={pollitem.title}
          percent={percents[index]}
          {...pollitem}
          disabled={voted}
        />
      ))}
    </form>
  )
}

const PollItem = ({ id, title, description, vote_url, disabled, percent }) => {
  return (
    <div className="polls__item">
      <button
        type="submit"
        data-id={id}
        data-vote={vote_url}
        className="polls__item-button"
        disabled={disabled}
        style={
          disabled
            ? {
                background: `linear-gradient( to right,var(--blood-orange) ,var(--orange) ${percent}%,var(--engagement-bg-1) ${percent}%,var(--engagement-bg-1)`,
              }
            : {}
        }
      >
        <div className="polls__item-content">
          <h3>{title}</h3>
        </div>

        {disabled ? <span>{percent}%</span> : null}
      </button>
      {description ? (
        <p className="polls__item-description">{description}</p>
      ) : null}
    </div>
  )
}
