import { getCookie } from "../utils/cookies"

function sendForm(form) {
  const data = {
    first_name: form.first_name.value,
    last_name: form.last_name.value,
    email: form.email.value,
  }

  fetch(form.action, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    response.json().then((data) => {
      form.innerHTML = data.message
    })
  })
}

export function initRegistrationForm() {
  const form = document.querySelector("[data-registration-form]")
  form.addEventListener("submit", (e) => {
    e.preventDefault()
    sendForm(form)
  })
}
